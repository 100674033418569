import { ReactNode } from 'react';
import { useModal } from 'react-modal-hook';

import ACFImage from 'types/ACFImage';
import ACFSignup from 'types/ACFSignup';

import Link from 'components/link/link';
import ScheduleDemoModal from 'components/modal/schedule-demo/schedule-demo-modal';
import SchedulingSignup from 'components/modal/signups';

import './styles/enterprise.scss';

type HeroEnterpriseProps = {
  background_desktop_image: ACFImage
  background_mobile_image: ACFImage
  copy: ReactNode
  customer_info: {
  business: string,
  date: string,
  name: string
},
link?: {
    text: string,
    url: string
},
  logosDesktop?: string,
  logosMobile?: string,
  scheduleDemoClassName?: string,
  schedule_demo?: {
    button_text: string
    schedule_demo: string
  },
  signup?: ACFSignup,
  sub_title: ReactNode,
  textBlock?: ReactNode,
  title: ReactNode
}

export const HeroEnterprise = (props:HeroEnterpriseProps) => {
  const [showScheduleDemoModal, hideScheduleDemoModal] = useModal(() => (
    <ScheduleDemoModal isOpen={ true } onHide={ hideScheduleDemoModal } />
  ));
  const HeroCtaButtons = () => {


  
    if (props.signup) return (
      <SchedulingSignup
        uniqueId={ props.signup.unique_id }
        modalTitle={ props.signup.modal_title }
        showInitFields={ true }
        buttonInline
        fieldLayout="row"
        signupModalLaunch={ props.signup.launch_modal_button_text }
        signupModalSubmit={ props.signup.modal_submit_button_text }
      />
    );
    
    if (props.link?.url) return (
      <Link className="button is-primary is-rounded" to={ props.link.url }>{props.link.text}</Link>
    );
  
    if (!props.signup && !props.link?.url) return (
      <button
        type="button"
        onClick={ showScheduleDemoModal }
        className={ `${
          props.scheduleDemoClassName ?? ''
        } intercom-launch-demo` }
      >
        {props.schedule_demo?.button_text}
      </button>
    );
    
    return null;
  };

  return (
    <section className="enterprise-hero-section section hero" data-section="heroDefaultSignup">
      <div className="hero-main container">
        <div className="hero-left">
          <header>
            <h1 className="subheadline">{props.sub_title}</h1>
            <h2 className="headline hero-headline">{props.title}</h2>
            <p className="header-copy">{props.copy}</p>
          </header>
          <div className="signup-cta-container">
            <HeroCtaButtons />
          </div>
          {props.logosDesktop && (<img
            src={ props.logosDesktop }
            alt="Logos of our enterprise customers"
            className="is-hidden-mobile logos"
          />)}
          {props.textBlock && (
            <p>Not going to HR Tech?       
              <button
                type="button"
                onClick={ showScheduleDemoModal }
                className="button-link"
              >
                Schedule a virtual demo now.
              </button></p>)}
        </div>

        <picture className="hero-image">
          <source
            srcSet={ props.background_desktop_image.source_url }
            media="(min-width: 769px)"
          />
          <img
            src={ props.background_mobile_image.source_url }
            alt={ props.background_desktop_image.alt_text }
          />
          {props.customer_info && (
            <div className="industry-card-customer-info">
              <p className="name">{props.customer_info.name}</p>
              <p className="business">{props.customer_info.business}</p>
              <p className="date">{props.customer_info.date}</p>
            </div>
          )}
        </picture>
        {props.logosMobile && (<img
          src={ props.logosMobile }
          alt="Logos of our enterprise customers"
          className="is-hidden-tablet logos"
        />)}
      </div>
    </section>
  );
};
