import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import { FeaturesList } from 'components/content-blocks/features-list';
import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import Yoast from 'components/globals/yoast';
import { HeroEnterprise } from 'components/hero/enterprise';

import './styles/manufacturing.scss';

const ManufaturingLandingPage = () => {
  const data = useStaticQuery(graphql`
  {
    allWordpressPage(filter: {slug: {eq: "manufacturing-landing-page"}}) {
      edges {
        node {
          wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          acf {
            hero {
              title
              sub_title
              copy
              schedule_demo {
                button_text
              }
              background_desktop_image {
                source_url
              }
              background_mobile_image {
                source_url
              }
            }
            media_object {
              body
              sub_title
              title
              desktop_image {
                alt_text
                source_url
              }
              mobile_image {
                alt_text
                source_url
              }
              button_url
              button_text
            }
            footer_signup {
                header
                button_text
              }
            features_list {
              sub_title
              title
              items {
                icon
                body
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  source_url
                  alt_text
                }
                title
              }
            }
            cards_1 {
                header {
                  title
                }
                card {
                  copy
                  title
                }
              }
          }
        }
      }
    }
  }
  `);
  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const mediaObject = pageContext.media_object;
  const featuresList = pageContext.features_list;
  const cards1 = pageContext.cards_1;
  return (
    <Layout
      showScheduleDemoButton
      showInitFields={ false }
      footerSignupHeader={ pageContext.footer_signup.header }
      footerSignupButtonText={ pageContext.footer_signup.button_text }
    >
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <div className="lp-manufacturing-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroEnterprise
          { ...hero }
          scheduleDemoClassName="hero button is-primary is-rounded intercom-launch-demo"
        />
        <MediaObject { ...mediaObject[0] } />
        <MediaObject { ...mediaObject[1] } className="reverse" />
        <MediaObject { ...mediaObject[2] } />
        <FeaturesList { ...featuresList } />
        <HighlightCards { ...cards1 } className="highlight-stats" hasTitle hasCopy />
      </div>
    </Layout>
  );
};

export default ManufaturingLandingPage;